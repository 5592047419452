@import '../config/custom-variables';

.ng-select-taggable .ng-dropdown-panel .scroll-host {
  position: unset !important;
}

// make 'add option' sticky
.ng-select-taggable:not(.ng-select-taggable--not-sticky)
  .ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:last-child {
  position: absolute;
  width: 100%;
  top: 100%;
}

.ng-select {
  .ng-select-container {
    border-radius: 0.25rem;
    min-height: unset;
  }

  &.ng-select-bottom.ng-select-opened,
  &.ng-select-top.ng-select-opened {
    & > .ng-select-container {
      border-radius: 0.25rem;
    }
  }

  // <-- start custom css for icons

  .ng-arrow-wrapper {
    width: 2rem;

    .ng-arrow {
      border: none;
    }
  }

  .ng-clear-wrapper {
    margin-top: 0.125rem;
    margin-right: 0.5rem;
    text-align: center;
  }

  // end custom css for icons <--

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: var(--primaryColor);
      box-shadow: none;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container {
    .ng-value {
      overflow: hidden;
      background-color: var(--gray-400);

      .ng-value-icon.right {
        border: 0;

        &:hover {
          background-color: var(--gray-500);
        }
      }
    }

    .ng-placeholder {
      top: auto; // in ng-select style sheet this is 5px, no idea why?
    }
  }
}

// <-- start custom css for icons

.ng-select-searchable {
  .ng-select-container {
    &:after {
      content: '';
      position: absolute;
      width: 1rem;
      height: calc(2rem + 2px);
      right: 0.75rem;
      top: 0;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='rgb(104,121,142)' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E")
        no-repeat center;
      background-size: 0.875rem;
      pointer-events: none;
      transition: all 0.3s ease;
      transform: rotate(0deg);
      transform-origin: center;
      margin: 0;
      border: 0;
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-color: var(--primaryColor);

      &:after {
        transform: rotate(180deg);
        margin: 0;
        border: 0;
      }
    }
  }
}

// bij een disabled ng-select MET een waarde moet zowel de inhoud als de arrow grey-500 worden.
// Doordat we een custom arrow hebben (sigh), moeten we voor de arrow grey-500 zijn rgb aanpassen.
fieldset:disabled .ng-select-searchable,
.ng-select-disabled.ng-select-searchable {
  .ng-select-container {
    .ng-value-container {
      color: var(--gray-500);
    }

    &:after {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='rgb(166,178,196)' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E")
        no-repeat center;
    }
  }
}

.ng-select-typeahead {
  .ng-select-container {
    &:after {
      content: '';
      position: absolute;
      width: 1rem;
      height: calc(2rem + 2px);
      right: 0.75rem;
      top: 0;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='rgb(104,121,142)' d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E")
        no-repeat center;
      background-size: 0.875rem;
      pointer-events: none;
      transition: all 0.3s ease;
      transform: rotate(0deg);
      margin: 0;
      border: 0;
    }
  }

  &.typeahead-plus {
    .ng-select-container {
      &:after {
        background: url('data:image/svg+xml,<svg role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="rgb(104,121,142)" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path></svg>')
          no-repeat center;
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      &:after {
        transform: rotate(0deg);
        margin: 0;
        border: 0;
      }
    }
  }
}

// end custom css for icons <--

.ng-select-focused {
  &:not(.ng-select-opened) > .ng-select-container {
    border: 1px solid var(--primaryColor);
    box-shadow: none;
  }
}

.ng-select .ng-select-container {
  border: 1px solid var(--gray-400);
  cursor: pointer !important;

  .ng-value-container {
    .ng-input > input {
      cursor: pointer !important;
    }

    .ng-placeholder {
      font-size: 0.75rem;
      color: var(--gray-500);
    }
  }

  &:hover {
    box-shadow: none;
  }
}

.ng-select-container--disabled {
  cursor: not-allowed !important;
  background-color: var(--gray-200) !important;
  border-color: var(--gray-400);

  .ng-value-container .ng-input > input {
    cursor: not-allowed !important;
  }
}

.ng-select-disabled {
  .ng-select-container {
    @extend .ng-select-container--disabled;
  }
}

// no extra padding for child options
.ng-dropdown-panel .ng-dropdown-panel-items {
  .ng-option {
    &.ng-option-marked,
    &.ng-option-selected,
    &.ng-option-marked.ng-option-selected {
      background-color: var(--primaryColorWashed);

      .ng-option-label {
        font-weight: normal;
      }
    }

    &.ng-option-child {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &.ng-option {
      &--loading,
      &--type-to-search {
        color: var(--gray-800);
      }

      &--not-found {
        color: var(--gray-800);
      }
    }
  }
}

// 'auto-grow-vertical' is our default {
.ng-option-label {
  white-space: normal;
}

// ng-select's default is to shorten the options with ellipsis, but we don't like that {
.auto-grow-disabled {
  .ng-option-label {
    white-space: nowrap;
  }
}

.ng-dropdown-panel {
  width: auto;
  border-radius: 4px;
  border: none;
  box-shadow: $dropdown-box-shadow;

  &.ng-select-top {
    bottom: calc(100% + 0.5rem);
    border-radius: 4px;
  }

  &.ng-select-bottom {
    top: calc(100% + 0.5rem);
    border-radius: 4px;
  }
}

.ng-dropdown-panel {
  z-index: $cdk-z-index-overlay-container + 1 !important;

  .ng-dropdown-panel-items .ng-option .highlighted {
    text-decoration: none !important;
    font-weight: 500 !important;
  }
}

// hack as a workaround for https://github.com/ng-select/ng-select/issues/1631
ng-select.ng-select--practitioner .ng-dropdown-panel,
ng-select.ng-select--health-element-code .ng-dropdown-panel,
.ng-dropdown-panel.ng-select--practitioner, // when appended to the body, custom classes are set on ng-dropdown-panel
.ng-dropdown-panel.ng-select--health-element-code {
  .ng-dropdown-panel-items {
    .ng-option {
      &--loading,
      &--type-to-search,
      &--not-found {
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
        }
      }

      &--add-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
  }
}

ng-select:not(.ng-select-multiple).ng-select--health-element-code {
  .ng-value-container {
    .ng-value {
      width: 100% !important;
    }
  }
}

ng-select.ng-select--cooperation-participant {
  .ng-select-container {
    border: none;
    border-radius: 0;
  }

  &.ng-select.ng-select-typeahead .ng-select-container:after {
    content: '';
    position: absolute;
    width: 1.1rem;
    height: calc(2rem + 2px);
    right: 0.75rem;
    top: 0;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='rgb(104,121,142)' d='M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z'/%3E%3C/svg%3E")
      no-repeat center;
    background-size: 1rem;
    pointer-events: none;
    transition: all 0.3s ease;
    transform: rotate(0deg);
    margin: 0;
    border: 0;
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border: none;
      box-shadow: none;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container {
    padding: 0;
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    padding-top: 0.25rem;

    .ng-value {
      margin-bottom: 0.25rem;
      background-color: var(--gray-400);

      .ng-value-icon.left,
      &.ng-value-icon.left:hover {
        border: none;
        background-color: inherit;
      }
    }

    .ng-input {
      padding: 0;
    }

    .ng-placeholder {
      padding-bottom: 0;
    }
  }

  .ng-dropdown-footer {
    border-top: 1px solid var(--gray-300);
  }
}

.option-row--icon {
  display: flex;

  fa-icon {
    margin-right: 0.5rem;
  }
}

.ng-optgroup {
  border-bottom: 1px solid var(--gray-400);

  span.ng-option-label {
    font-weight: 600;
    font-size: 0.75rem;
    color: gray;
  }
}

// custom class to hide group titles - note that when the dropdown is appended to the body, it does not
// appear underneath ng-select, hence we also check where it is underneath ng-dropdown-panel
ng-select.group-no-titles,
ng-dropdown-panel.group-no-titles {
  .ng-optgroup {
    padding: 0 !important;

    span.ng-option-label {
      display: none;
    }
  }
}

ng-select.highlight {
  .ng-value .ng-value-label {
    background-color: var(--primaryColorWashed);
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
}

.input-group {
  .input-group-controls {
    .ng-select {
      .ng-select-container {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
}

.rsoep-container__form {
  .input-group {
    .input-group-controls {
      .ng-select {
        .ng-select-container {
          padding: 0.25rem 0.75rem;

          .ng-value-container {
            padding: 0;

            .ng-placeholder {
              padding: 0;
            }

            .ng-input {
              padding: 0;
            }

            .ng-value {
              margin: 0;
              border-radius: 0.25rem;
              border: 2px solid var(--white);
            }
          }
        }
      }
    }
  }
}

ng-select.form-control {
  border: none;
  padding: 0 !important;
}

fieldset:disabled {
  .ng-select-container {
    @extend .ng-select-container--disabled;
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: calc(1.5em + 0.75rem + 2px);
}

.ng-select .ng-select-container {
  min-height: calc(1.5em + 0.75rem + 2px);
}

.ng-select-searchable {
  &.ng-select-clearable {
    &.ng-select-opened {
      .ng-spinner-loader {
        margin-right: 0;
      }
    }

    .ng-has-value {
      .ng-spinner-loader {
        margin-right: 0;
      }
    }
  }

  .ng-select-container {
    .ng-spinner-loader {
      margin-right: 0.5rem;
    }
  }
}
