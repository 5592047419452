$types: success, warning, danger;

table,
.table {
  margin-bottom: 0;


  // === table styling ===

  &--striped {
    tbody tr:nth-child(even) {
      background-color: var(--gray-100);
    }
  }

  &--bordered,
    // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: table-bordered is not used anymore and can be removed
  &-bordered {
    border: 1px solid var(--gray-300);
  }

  tr {


    // === 'deprecated' row states ===

    // todo Will be removed as part of https://nexuzhealth.atlassian.net/browse/FEG-1591
    &.danger {
      background-color: var(--dangerColorWashed);

      > td:first-child {
        box-shadow: -3px 0 0 0 var(--dangerColor);
      }

      td {
        background-color: var(--dangerColorWashed);
      }

      &:hover td {
        background-color: var(--dangerColorWashedHover);
      }

      &.detail-row {
        td {
          cursor: default;
          background-color: var(--dangerColorLightWashed);
        }

        &:hover td {
          background-color: var(--dangerColorLightWashed);
        }
      }
    }

    // todo Will be removed as part of https://nexuzhealth.atlassian.net/browse/FEG-1591
    &.success {
      background-color: var(--successColorWashed);
    }

    // todo Will be removed as part of https://nexuzhealth.atlassian.net/browse/FEG-1591
    &.warning {
      background-color: var(--warningColorWashed);

      > td:first-child {
        box-shadow: -3px 0 0 0 var(--warningColor);
      }

      td {
        background-color: var(--warningColorWashed);
      }

      &:hover td {
        background-color: var(--warningColorWashedHover);
      }

      &.detail-row {
        td {
          cursor: default;
          background-color: var(--warningColorLightWashed);
        }

        &:hover td {
          background-color: var(--warningColorLightWashed);
        }
      }
    }

    // todo Will be removed as part of https://nexuzhealth.atlassian.net/browse/FEG-1591
    &.active {
      > td:first-child {
        box-shadow: -3px 0 0 0 var(--primaryColor);
      }
    }

    // todo Will be removed as part of https://nexuzhealth.atlassian.net/browse/FEG-1591
    &.active td {
      color: var(--gray-800);
      background-color: var(--primaryColorWashed) !important;
    }

    // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: only used in list-textsnippets-page and
    //  template/overview-page. Review if we need it or move it to that module
    &.expanded {
      > td:first-child {
        box-shadow: -3px 0 0 0 var(--primaryColor);
      }
    }

    // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: not used anymore, can be removed
    &.disabled {
      td,
      &:hover td,
      &.active, &.row--active {
        border-left: none;
        color: var(--gray-600);
        background-color: transparent;
        cursor: not-allowed;
      }

      td:first-child {
        padding-left: 1.5rem;
      }
    }


    // === summary row ===

    &.summary-row th,
    &.summary-row td {
      color: var(--gray-800);
      background-color: var(--gray-100);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-weight: 500;
    }


    // === th td styling ===

    th {
      padding: 1rem 0.5rem;
      font-size: 0.875rem;
      color: var(--gray-600);
      font-weight: 500;
      border-top: 0;
      border-bottom: 1px solid var(--gray-300);
      vertical-align: top;
      white-space: nowrap;

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }
    }

    td {
      padding: 0.75rem 0.5rem;

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }

      // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: 'cell-link' only used in patient-list.component, move this
      //  to that module
      &.cell-link:has(> a) {
        padding: 0;

        &:first-child {
          padding-left: 0;

          > a {
            padding-left: 1.5rem;
          }
        }

        &:last-child {
          padding-right: 0;
        }

        > a {
          padding: 0.75rem 0.5rem;

          &:link,
          &:visited {
            color: inherit;
          }

          &:last-child {
            padding-right: 1.5rem;
          }
        }
      }

      font-size: 0.875rem;
      vertical-align: top !important;
      border-top: 0;
      border-bottom: 1px solid var(--gray-300);


      // === actions column ===

      &.cdk-column-actions,
      &.actions-column {
        // = font-size * line-height divided by 2, plus the column padding - half of the btn-size
        padding-bottom: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);
        padding-top: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);

        &.cell-link:has(> a) {
          padding-bottom: 0;
          padding-top: 0;

          > a {
            // = font-size * line-height divided by 2, plus the column padding - half of the btn-size
            padding-bottom: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);
            padding-top: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);
          }
        }
      }

      // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: review, only used in finance?
      &[rowspan] {
        vertical-align: top !important;
        background-color: var(--white);
        border-right: 1px solid var(--gray-300);
      }
    }

    // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: not used, remove this
    td,
    th {
      &.no-stretch {
        width: 1%;
      }
    }
  }

  // when a cell has a direct link, we "blow up" the link so the entire cell becomes clickable, this involves
  // - setting the link's height to 100% (which has only effect if its parent has a height)
  // - moving the paddings from the cell to the link (see below)
  // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: 'cell-link' only used in patient-list.component, move this
  //  to that module
  td.cell-link:has(> a) {
    > a {
      display: block;
    }
  }


  // === row sizes ===

  &--small {
    tr {
      td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &.cell-link:has(> a) {
          padding-top: 0;
          padding-bottom: 0;

          > a {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        &.cdk-column-actions,
        &.actions-column {
          padding-bottom: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);
          padding-top: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);

          &.cell-link:has(> a) {
            padding-bottom: 0;
            padding-top: 0;

            > a {
              padding-bottom: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);
              padding-top: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);
            }
          }

          nxh-icon-button > .btn,
          .btn-icon {
            height: 1.5rem;
          }
        }
      }
    }
  }

  &--large {
    tr {
      td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &.cell-link:has(> a) {
          padding-top: 0;
          padding-bottom: 0;

          > a {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
          }
        }

        &.cdk-column-actions,
        &.actions-column {
          padding-bottom: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);
          padding-top: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);

          &.cell-link:has(> a) {
            padding-bottom: 0;
            padding-top: 0;

            > a {
              padding-bottom: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);
              padding-top: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);
            }
          }
        }
      }
    }
  }


  // === hoverable table ===

  &--hoverable, &--clickable {
    tr:not(.row--active):not(.detail-row):not(.nxh-disabled):not(.row--not-clickable):hover td:not([rowspan]) {
      background-color: var(--primaryColorWashed);
    }

    @each $type in $types {
      // tr.#{$type} for backwards compatibility
      tr.row--status-#{$type}, tr.#{$type} {
        &:not(.row--active):not(.detail-row):not(.nxh-disabled):not(.row--not-clickable):hover {
          td:not([rowspan]) {
            background-color: var(--#{$type}ColorWashedHover) !important;
          }
        }
      }
    }
  }

  &--clickable {
    tr:not(.nxh-disabled):not(.row--not-clickable) {
      cursor: pointer;

      th {
        cursor: auto;
      }
    }
  }

  // === rowspan table ===

  &--rowspan {
    tr {
      td:not([rowspan]):first-of-type {
        padding-left: 0.5rem;
      }
    }
  }

  // todo https://nexuzhealth.atlassian.net/browse/FEG-1573: review, where is this used?
  tr.summary-row {
    &:hover td {
      background-color: transparent;
      cursor: default;
    }
  }
}

//unfortunately CdkRowDefWhen does not work on CdkHeaderRow, so we have to use css to hide the total headers when there is no data
// use this in combination with hide-summary-rows-when-empty.directive
.hide-summary-rows {
  .summary-row {
    display: none;
  }
}

// todo https://nexuzhealth.atlassian.net/browse/FEG-1573: not used anymore, remove this
.check-circle {
  color: var(--successColor);
}

// todo https://nexuzhealth.atlassian.net/browse/FEG-1573: not used anymore, remove this
.times-circle {
  color: var(--dangerColor);
}

// todo https://nexuzhealth.atlassian.net/browse/FEG-1573: not used anymore, remove this
.exclamation-circle {
  color: var(--warningColor);
}


// === table with checkboxes ===

.checkbox-table {
  position: relative;

  .table-actions {
    position: absolute;
    top: 0;
    left: 50px;
    right: 0;
    background: white;
    padding: 0.5rem;
  }
}


// === actions column ===

// todo https://nexuzhealth.atlassian.net/browse/FEG-1573: only used in list-floating-appointments, move to that module
th.actions {
  text-align: right;

  .icons {
    display: flex;
    justify-content: flex-end;
  }
}

.cdk-column-actions {
  text-align: right;

  //use this if the actions column contains multiple action buttons
  > div.actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
  }

  > button:not(:first-child) {
    margin-left: 0.25rem;
  }
}

// used by table-loader
.table-is-loading {
  min-height: 15rem;
}

// used by table-loader
.table-errorstate {
  min-height: 20rem;
}


// === disabled states ===

tr.nxh-disabled {
  td,
  &:hover td,
  &.active, &.row--active td {
    cursor: not-allowed;
    color: var(--gray-500);

    * {
      // note pointer-events:none only on child nodes, impossible to get cursor correct while setting pointer events to none
      // disabling pointer-events on the root is done via javascript
      pointer-events: none;
    }

    a {
      color: var(--gray-500);
    }
  }
}

td.nxh-disabled {
  cursor: not-allowed;
  color: var(--gray-500);

  * {
    pointer-events: none;
  }

  a {
    color: var(--gray-500);
  }
}


// === alignments ===

th.nxh-align-right + th.nxh-align-left,
td.nxh-align-right + td.nxh-align-left,
th.nxh-align-right + th:not(.nxh-align-right):not(.nxh-align-center),
td.nxh-align-right + td:not(.nxh-align-right):not(.nxh-align-center) {
  padding-left: 1.5rem; // = default 0.5 + 1 rem extra
}


// === stickers ===

tr.sticker-target:has(.sticker--top-left),
tr.sticker-target:has(.sticker--top-right),
tr.sticker-target:has(.sticker--bottom-right),
tr.sticker-target:has(.sticker--bottom-left) {
  position: relative;
}


// === row states ===

tr {
  @each $type in $types {
    &.row--status-#{$type}:not(.row--active) {

      // !important to overwrite gray in striped mode
      background-color: var(--#{$type}ColorWashed) !important;

      // as you can see, row--bordered only has an effect in combination with a status!
      &.row--status-#{$type}.row--bordered {
        > td:first-child {
          box-shadow: -3px 0 0 0 var(--#{$type}Color);
        }
      }

      &.row--hoverable, &.row--clickable {
        &:not(.row--active):not(.detail-row):not(.nxh-disabled):hover {
          td:not([rowspan]) {
            background-color: var(--#{$type}ColorWashedHover);
          }
        }
      }
    }
  }

  &.row--active {
    > td:first-child {
      box-shadow: -3px 0 0 0 var(--primaryColor);
    }

    color: var(--gray-800);
    background-color: var(--primaryColorWashed) !important;
  }

  &.row--hoverable, &.row--clickable {
    &:not(.row--active):not(.detail-row):not(.nxh-disabled):hover {
      td:not([rowspan]) {
        background-color: var(--primaryColorWashed);
      }
    }
  }

  &.row--clickable {
    cursor: pointer !important;

    th {
      cursor: auto;
    }
  }

}