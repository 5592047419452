.detail-bubble {
  height: 100%;
  width: 28.5rem;

  &__title {
    font-size: 1.125rem;
    font-weight: 500;
    padding: 0.4rem 0;
    display: flex;
  }

  &--no-capitalization-title {
    .detail-bubble__title::first-letter {
      text-transform: lowercase;
    }
  }

  &__controls {
    height: auto;
    margin-top: 0;
    button:not([disabled]) fa-icon svg.fa-trash-can {
      color: var(--dangerColor);
    }
  }

  .card-container {
    display: flex;
    align-items: stretch;

    :not(.nxh-card-component).card {
      flex-grow: 1;
      box-shadow: 0 0.125rem 1.25rem rgba(144, 150, 170, 0.2);
      margin: 0;

      &-header {
        position: relative;
        border-bottom: 0;
        align-items: flex-start;
        padding: 0.5rem 1.5rem;
        border-bottom: 1px solid var(--gray-400);
      }

      &-body__container {
        flex-wrap: wrap;

        fa-icon {
          min-width: 1rem;
        }

        hr {
          flex: 0 0 100%;
          margin: 1rem 0 0 0; //currently overwritten by tabnav.css, behaviour might not be what you expect
          border-color: var(--gray-300);
        }
      }
    }
  }
}

.arrow-container--horizontal {
  position: relative;
  height: 1rem;

  .arrow--up {
    position: absolute;
    border-left: 1rem solid transparent;
    border-bottom: 1rem solid var(--white);
    border-right: 1rem solid transparent;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .arrow--down {
    position: absolute;
    border-left: 1rem solid transparent;
    border-top: 1rem solid var(--white);
    border-right: 1rem solid transparent;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}

.arrow-container--vertical {
  position: relative;
  width: 1rem;

  .arrow--left {
    position: absolute;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid var(--white);
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .arrow--right {
    position: absolute;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid var(--white);
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}

.popper--bottom {
  .arrow--up {
    display: block;
  }

  .arrow--down,
  .arrow--left,
  .arrow--right {
    display: none;
  }

  .detail-bubble {
    margin-top: -1rem;
  }
}

.popper--top {
  .arrow--up,
  .arrow--left,
  .arrow--right {
    display: none;
  }

  .arrow--down {
    display: block;
  }

  .detail-bubble {
    margin-bottom: -1rem;
  }
}

.popper--left {
  .arrow--up,
  .arrow--left,
  .arrow--down {
    display: none;
  }

  .arrow--right {
    display: block;
  }

  .detail-bubble {
    margin-bottom: -1rem;
  }
}

.popper--right {
  .arrow--up,
  .arrow--right,
  .arrow--down {
    display: none;
  }

  .arrow--left {
    display: block;
  }

  .detail-bubble {
    margin-bottom: -1rem;
  }
}

.bubble-content {
  padding: 1rem;

  &__row {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  &__icon {
    color: var(--gray-500);
    line-height: 1rem;
  }
}
